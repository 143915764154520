<template>
  <div></div>
</template>

<script>
export default {
  props: {
    stylePack: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  async mounted() {
    this.setChannelPosition({ isRemove: false })
  },
  async destroyed() {
    this.setChannelPosition({ isRemove: true })
  },
  methods: {
    getLauncher(plugin) {
      const query = '#ch-shadow-root-wrapper div[data-ch-testid=\'launcher\']'
      return plugin?.shadowRoot?.querySelector(query)
    },
    getChannelByQuery(timeout) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const plugin = document.querySelector('#ch-plugin-entry > div')
          const launcher = this.getLauncher(plugin)
          if (launcher) {
            resolve(plugin)
          } else {
            reject(new Error())
          }
        }, timeout)
      })
    },
    async setChannelPosition({ isRemove = false }) {
      const q = this.getChannelByQuery
      try {
        const plugin = await Promise.any([q(100), q(1000), q(1750)])
        const launcher = this.getLauncher(plugin)
        let styleEl = plugin.shadowRoot.getElementById('custom-style')
        if (!styleEl) {
          styleEl = document.createElement('style')
          styleEl.setAttribute('id', 'custom-style')
        }

        for (const className in this.stylePack) {
          if (isRemove === true) {
            launcher.classList.remove(className)
          } else {
            const styleText = this.stylePack[className]
            styleEl.appendChild(document.createTextNode(`.${className} { ${styleText} }`))
            plugin.shadowRoot.appendChild(styleEl)
            if (!launcher.classList.contains(className)) {
              launcher.classList.add(className)
            }
          }
        }
      } catch (e) {}
    },
  },
}
</script>
